import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';

const NovelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  max-width: 800px;
`;

const NovelTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const NovelAuthor = styled.p`
  text-align: center;
  margin-bottom: 10px;
`;

const NovelAbstract = styled.p`
  line-height: 1.6;
  white-space: pre-wrap;
  text-align: left;
  margin-bottom: 20px;
`;

const ChapterList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ChapterLink = styled(Link)`
  display: block;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const HomeLink = styled(Link)`
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 1;
`;

const HomeIcon = styled.span`
  font-size: 2rem;
  color: #333;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const NovelIntroduction = () => {
  const { novelId } = useParams();
  const [novelData, setNovelData] = useState(null);

  useEffect(() => {
    const fetchNovelData = async () => {
      try {
        const response = await axios.get(`https://oexnab1hue.execute-api.ap-southeast-2.amazonaws.com/novels/${novelId}`);
        setNovelData(response.data);
      } catch (error) {
        console.error('Error fetching novel data:', error);
      }
    };

    fetchNovelData();
  }, [novelId]);

  return (
    <NovelContainer>
      <HomeLink to="/">
        <HomeIcon>&#8962;</HomeIcon>
      </HomeLink>
      {novelData ? (
        <>
          <NovelTitle>{novelData.intro.title}</NovelTitle>
          <NovelAuthor>By {novelData.intro.author}</NovelAuthor>
          <NovelAbstract>{novelData.intro.abstract.split('\\r\\n').map((row, index) => (
            <React.Fragment key={index}>
              {row}
              <br />
            </React.Fragment>
          ))}</NovelAbstract>
          <h3>Chapters</h3>
          <ChapterList>
            {novelData.chapters.map((chapter) => (
              <li key={chapter.id}>
                <ChapterLink to={`/novels/${novelId}/chapter/${chapter.id}`}>{chapter.title}</ChapterLink>
              </li>
            ))}
          </ChapterList>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </NovelContainer>
  );
};

export default NovelIntroduction;