// src/components/CardComponent.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const cardStyle = {
  border: '1px solid #ccc',
  padding: '20px',
  margin: '10px',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
};

const cardHoverStyle = {
  backgroundColor: '#f0f0f0',
};

const CardComponent = ({ title, path }) => {
  const navigate = useNavigate();
  const [hover, setHover] = React.useState(false);

  const handleClick = () => {
    navigate(path);
  };

  return (
    <div
      style={hover ? { ...cardStyle, ...cardHoverStyle } : cardStyle}
      onClick={handleClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <h2>{title}</h2>
    </div>
  );
};

export default CardComponent;
