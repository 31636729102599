import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Novels = () => {
  const [novels, setNovels] = useState([]);

  useEffect(() => {
    const fetchNovels = async () => {
      try {
        const response = await axios.get('https://oexnab1hue.execute-api.ap-southeast-2.amazonaws.com/novels'); // Replace with your API endpoint
        setNovels(response.data);
      } catch (error) {
        console.error('Error fetching novels:', error);
      }
    };

    fetchNovels();
  }, []);

  return (
    <NovelsContainer>
      <HomeLink to="/">
        <HomeIcon>&#8962;</HomeIcon>
      </HomeLink>
      <Title>Novels</Title>
      <NovelsGrid>
        {novels.map((novel) => (
          <NovelCard key={novel.id}>
            <NovelImage src={`/images/novel${novel.id}.jpg`} alt={novel.title} />
            <NovelDetails>
              <NovelTitle>
                <Link to={`/novels/${novel.id}`}>{novel.title}</Link>
              </NovelTitle>
              <NovelAuthor>by {novel.author}</NovelAuthor>
              <NovelAbstract>
                {novel.abstract.split('\n').slice(0, 3).join('\n')}
              </NovelAbstract>
            </NovelDetails>
          </NovelCard>
        ))}
      </NovelsGrid>
    </NovelsContainer>
  );
};

const NovelsContainer = styled.div`
  padding: 2rem;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const HomeLink = styled(Link)`
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 1;
`;

const HomeIcon = styled.span`
  font-size: 2rem;
  color: #333;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const NovelsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;
`;

const NovelCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const NovelImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const NovelDetails = styled.div`
  padding: 1rem;
`;

const NovelTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  a {
    color: #333;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const NovelAuthor = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
`;

const NovelAbstract = styled.p`
  font-size: 0.9rem;
  color: #888;
  line-height: 1.4;
  white-space: pre-wrap;
`;

export default Novels;