import React from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const ChapterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  max-width: 800px;
`;

const ChapterTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const ChapterContent = styled.p`
  line-height: 1.6;
  white-space: pre-wrap;
`;

const HomeLink = styled(Link)`
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 1;
`;

const HomeIcon = styled.span`
  font-size: 2rem;
  color: #333;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const IntroductionLink = styled(Link)`
  position: fixed;
  top: 4rem; /* Adjust the top position as needed */
  left: 1rem;
  z-index: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;

  @media (max-width: 768px) {
    top: 3rem; /* Adjust the top position for smaller screens */
  }
`;

const IntroductionIcon = styled.span`
  font-size: 1.5rem;
  margin-right: 0.5rem;
`;

const IntroductionText = styled.span`
  font-size: 1rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavigationButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  width: 100%;
  margin: 20px 0;
`;

const NavButton = styled(Link)`
  display: inline-block;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
`;

const PlaceholderDiv = styled.div`
  grid-column: 1 / 2;
`;

const ChapterContentComponent = () => {
  const { novelId, chapterId } = useParams();
  const [chapterData, setChapterData] = useState(null);

  useEffect(() => {
    const fetchChapterData = async () => {
      try {
        const response = await axios.get(`https://oexnab1hue.execute-api.ap-southeast-2.amazonaws.com/novels/${novelId}/chapter/${chapterId}`);
        setChapterData(response.data);
      } catch (error) {
        console.error('Error fetching chapter data:', error);
      }
    };

    fetchChapterData();
  }, [novelId, chapterId]);

  const currentChapterId = parseInt(chapterId);
  const prevChapterId = currentChapterId > 1 ? currentChapterId - 1 : null;
  const nextChapterId = currentChapterId + 1;

  const handleNavClick = (event) => {
    event.preventDefault();
    const targetHref = event.currentTarget.getAttribute('href');
    window.location.href = targetHref + window.location.hash;
  };

  return (
    <ChapterContainer>
      <HomeLink to="/">
        <HomeIcon>&#8962;</HomeIcon>
      </HomeLink>
      <IntroductionLink to={`/novels/${novelId}`}>
        <IntroductionIcon>&#9776;</IntroductionIcon>
        <IntroductionText>Introduction and Chapters</IntroductionText>
      </IntroductionLink>
      {chapterData ? (
        <>
          <ChapterTitle>{chapterData.title}</ChapterTitle>
          <ChapterContent>{chapterData.content.split('\\r\\n').map((row, index) => (
            <React.Fragment key={index}>
              {row}
              <br />
            </React.Fragment>
          ))}</ChapterContent>
        </>
      ) : (
        <p>Loading...</p>
      )}
      <NavigationButtons>
        {prevChapterId ? (
          <NavButton 
            to={`/novels/${novelId}/chapter/${prevChapterId}`}
            onClick={handleNavClick}
          >
            Previous Chapter
          </NavButton>
        ) : (
          <PlaceholderDiv />
        )}
        <NavButton to={`/novels/${novelId}`}>
          More Chapters
        </NavButton>
        <NavButton 
          to={`/novels/${novelId}/chapter/${nextChapterId}`}
          onClick={handleNavClick}
        >
          Next Chapter
        </NavButton>
      </NavigationButtons>
    </ChapterContainer>
  );
};

export default ChapterContentComponent;