import React from 'react';
import ShareComponent from './ShareComponent';

const Layout = ({ children }) => {
  const currentUrl = window.location.href;
  const pageTitle = document.title;

  return (
    <div className="layout">
      {children}
      <ShareComponent url={currentUrl} title={pageTitle} />
    </div>
  );
};

export default Layout;