import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Translation.css';

const TranslationCard = ({ originalText, translatedText, onDoubleClick, isDoubleClicked }) => {
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    setTouchEnd(e.changedTouches[0].clientX);
    if (touchStart === touchEnd) {
      onDoubleClick();
    }
  };

  return (
    <div
      className={`translation-card ${isDoubleClicked ? 'double-clicked' : ''}`}
      onDoubleClick={onDoubleClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className="original-text">
        <strong>Original:</strong> {originalText}
      </div>
      <div className="translated-text">
        <strong>Translated:</strong> {translatedText}
      </div>
    </div>
  );
};

const Translation = () => {
  const [file, setFile] = useState(null);
  const [content, setContent] = useState([]);
  const [index, setIndex] = useState(0);
  const [showModelDropdown, setShowModelDropdown] = useState(false);
  const [showStartButton, setShowStartButton] = useState(false);
  const [started, setStarted] = useState(false);
  const [selectedModel, setSelectedModel] = useState('');
  const [translatedRows, setTranslatedRows] = useState([]);
  const [isTranslating, setIsTranslating] = useState(false); // Flag to prevent simultaneous translations
  const [isDownloadReady, setIsDownloadReady] = useState(false); // Flag to indicate if download is ready
  const [doubleClickedRows, setDoubleClickedRows] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      setShowModelDropdown(true);
    }
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
    setShowStartButton(true);
  };

  const handleStart = () => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result.split('\n');
      setContent(fileContent);
      setStarted(true);
      setIndex(0); // Reset index when starting
      setTranslatedRows([]); // Reset translations when starting
      setIsDownloadReady(false); // Reset download ready flag when starting
    };
    reader.readAsText(file);
  };

  const handleDownload = () => {
    // Prepare the content for download
    const contentForDownload = content.map((text, idx) => {
      const translatedText = translatedRows[idx] || '';
      if (doubleClickedRows.includes(idx)) {
        return `${idx + 1}: ${translatedText}\n${text}`;
      }
      return translatedText;
    }).join('\n');
  
    // Create a Blob with UTF-8 encoding
    const blob = new Blob([contentForDownload], { type: 'text/plain;charset=utf-8' });
  
    // Create a temporary link element to trigger the download
    const element = document.createElement("a");
    element.href = URL.createObjectURL(blob);
    element.download = "translated.txt";
  
    // Append the link to the body and trigger the click event
    document.body.appendChild(element);
    element.click();
  
    // Clean up by removing the link element from the DOM
    document.body.removeChild(element);
  };  

  const doubleClickHandler = (index) => {
    setDoubleClickedRows((prev) => [...prev, index]);
  };

  useEffect(() => {
    const translateRow = async (model, text) => {
      try {
        const response = await axios.post('https://oexnab1hue.execute-api.ap-southeast-2.amazonaws.com/translation', {
          model,
          userPrompt: text,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        return response.data.translated_text; // Assuming the API response contains the translated text in this field
      } catch (error) {
        console.error('Error translating text:', error);
        return 'Translation error';
      }
    };

    const translateAndUpdate = async () => {
      if (!started || isTranslating || index >= content.length) return;

      setIsTranslating(true); // Set translating flag

      const translatedText = await translateRow(selectedModel, content[index]);

      setTranslatedRows((prev) => {
        const updatedRows = [...prev];
        updatedRows[index] = translatedText; // Update specific index with translated text
        return updatedRows;
      });

      setIndex((prevIndex) => prevIndex + 1);
      setIsTranslating(false); // Clear translating flag

      if (index + 1 === content.length) {
        setIsDownloadReady(true); // Set download ready flag when all rows are translated
      }
    };

    const intervalId = setInterval(translateAndUpdate, 2000);

    return () => clearInterval(intervalId);
  }, [started, index, content, selectedModel, isTranslating]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://oexnab1hue.execute-api.ap-southeast-2.amazonaws.com/admin', { username, password }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.data.success) {
        alert('Login successful!');
        setIsLoggedIn(true);
      } else {
        alert('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="container">
      {!isLoggedIn ? (
        <div className="login-container">
          <h1>Login</h1>
          <form onSubmit={handleLogin} className="login-form">
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <button type="submit" className="btn">Login</button>
          </form>
        </div>
      ) : (
        <div className="translation-container">
          <h1>Translation Page</h1>
          <p>This is the translation page content.</p>
          <input type="file" onChange={handleFileChange} className="file-input" />
          {showModelDropdown && (
            <div className="model-dropdown">
              <label htmlFor="modelSelect">Select Model: </label>
              <select id="modelSelect" value={selectedModel} onChange={handleModelChange}>
                <option value="">--Please choose a model--</option>
                <option value="gpt-4o">gpt-4o</option>
                {/* <option value="gpt-4">gpt-4</option>
                <option value="gpt-4-turbo">gpt-4-turbo</option>
                <option value="claude-3-opus-20240229">Opus</option>
                <option value="claude-3-sonnet-20240229">Sonnet</option>
                <option value="gpt-3.5-turbo">gpt-3.5-turbo</option> */}
              </select>
            </div>
          )}
          {showStartButton && (
            <button onClick={handleStart} className="btn">Start</button>
          )}
          {content.map((originalText, idx) => (
            <TranslationCard
              key={idx}
              originalText={originalText}
              translatedText={translatedRows[idx] || 'Translating...'}
              onDoubleClick={() => doubleClickHandler(idx)}
              isDoubleClicked={doubleClickedRows.includes(idx)}
            />
          ))}
          {isDownloadReady && (
            <button onClick={handleDownload} className="btn download-btn">Download Translated File</button>
          )}
        </div>
      )}
    </div>
  );
};

export default Translation;
