import React, { useState } from 'react';
import axios from 'axios';
import './FreeChat.css'; // Import the CSS file

const FreeChat = () => {
    const [model, setModel] = useState('');
    const [userPrompt, setUserPrompt] = useState('');
    const [systemPrompt, setSystemPrompt] = useState('');
    const [answer, setAnswer] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state

    const chatbot = async () => {
        if (model && userPrompt && systemPrompt) {
            setLoading(true); // Set loading to true when API call starts
            try {
                const response = await axios.post('https://oexnab1hue.execute-api.ap-southeast-2.amazonaws.com/chatbot', {
                    model,
                    userPrompt,
                    systemPrompt,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setAnswer(response.data.Answer);
            } catch (error) {
                console.error('Error answering:', error);
                setAnswer('Chatbot error');
            } finally {
                setLoading(false); // Set loading to false when API call ends
            }
        } else {
            setAnswer('All fields must be filled');
        }
    };

    return (
        <div className="chat-container">
            <h1>Chatbot API Test</h1>
            <div>
                <label>
                    Model:
                    <input 
                        type="text" 
                        value={model} 
                        onChange={(e) => setModel(e.target.value)} 
                        className="model-input"
                    />
                </label>
            </div>
            <div>
                <label>
                    User Prompt:
                    <textarea 
                        value={userPrompt} 
                        onChange={(e) => setUserPrompt(e.target.value)} 
                        className="user-prompt-input"
                    />
                </label>
            </div>
            <div>
                <label>
                    System Prompt:
                    <textarea 
                        value={systemPrompt} 
                        onChange={(e) => setSystemPrompt(e.target.value)} 
                        className="system-prompt-input"
                    />
                </label>
            </div>
            <div>
                <button onClick={chatbot}>Send question</button>
            </div>
            <div>
                <h2>Answer:</h2>
                <p>{loading ? 'Loading...' : answer}</p>
            </div>
        </div>
    );
};

export default FreeChat;
