// src/components/AggregationComponent.js
import React from 'react';
import CardComponent from './CardComponent';

const AI = () => {
  const cards = [
    { title: 'FreeChat', path: '/ai/freechat' },
    { title: 'DALL-E Prompts Generator', path: '/ai/imageprompts' }
  ];

  return (
    <div>
      {cards.map((card, index) => (
        <CardComponent key={index} title={card.title} path={card.path} />
      ))}
    </div>
  );
};

export default AI;
