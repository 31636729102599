import React from 'react';
import './ShareComponent.css';

const ShareComponent = ({ url, title }) => {
  const shareLinks = [
    {
      name: 'Facebook',
      icon: 'facebook-f',
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
    },
    {
      name: 'Twitter',
      icon: 'twitter',
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`,
    },
    {
      name: 'LinkedIn',
      icon: 'linkedin-in',
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`,
    },
    {
      name: 'WhatsApp',
      icon: 'whatsapp',
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(title + ' ' + url)}`,
    },
    {
      name: 'Pinterest',
      icon: 'pinterest-p',
      url: `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&description=${encodeURIComponent(title)}`,
    },
    {
      name: 'Reddit',
      icon: 'reddit-alien',
      url: `https://reddit.com/submit?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`,
    },
    {
      name: 'Instagram',
      icon: 'instagram',
      url: `https://instagram.com/`, // Note: Instagram doesn't support direct sharing links
    },
    {
      name: 'TikTok',
      icon: 'tiktok',
      url: `https://www.tiktok.com/`, // Note: TikTok doesn't support direct sharing links
    },
    {
      name: 'Snapchat',
      icon: 'snapchat-ghost',
      url: `https://www.snapchat.com/`, // Note: Snapchat doesn't support direct sharing links
    },
  ];

  return (
    <div className="share-component">
      <div className="share-button">
        <span>Share</span>
      </div>
      <ul className="share-options">
        {shareLinks.map((link, index) => (
          <li key={index}>
            <a href={link.url} target="_blank" rel="noopener noreferrer" aria-label={`Share on ${link.name}`}>
              <i className={`fab fa-${link.icon}`}></i>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ShareComponent;