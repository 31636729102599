import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HomePage = () => {
  return (
    <HomeContainer>
      <Header>
        <Title>Koala Novels</Title>
      </Header>
      <ContentWrapper>
        <Introduction>
          <p>
            Welcome to Koala Novels, a delightful haven for lovers of Asian literature! We are thrilled to present a captivating collection of translated short novels, each masterfully crafted with words that transport you to enchanting worlds.
          </p>
          <p>
            Immerse yourself in our diverse array of stories, carefully curated to offer you a literary journey like no other. With word counts ranging up to 10,000, these bite-sized tales are perfect for those moments when you crave a satisfying and immersive reading experience without the commitment of a full-length novel.
          </p>
          <p>
            If you have any questions or concerns, please don't hesitate to reach out to us at <Email>koalanovels@gmail.com</Email>.
          </p>
          <p>
            We can't wait to share these literary treasures with you. Get ready to embark on an unforgettable adventure through the pages of Koala Novels!
          </p>
        </Introduction>
        <NovelsLink>
          <Link to="/novels">Browse Our Novels</Link>
        </NovelsLink>
      </ContentWrapper>
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Header = styled.header`
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #333;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
`;

const Introduction = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.6;
`;

const Email = styled.span`
  font-weight: bold;
`;

const NovelsLink = styled.div`
  a {
    display: inline-block;
    padding: 0.8rem 1.6rem;
    background-color: #333;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #555;
    }
  }
`;

export default HomePage;