import React, { useState } from 'react';
import axios from 'axios';

const styles = {
  adminPage: {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '100%',
    padding: '20px',
    boxSizing: 'border-box',
  },
  container: {
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  h2: {
    color: '#333',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: '10px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',
  },
  button: {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    padding: '10px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  checkbox: {
    marginRight: '10px',
  },
};

const AdminPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [novelId, setNovelId] = useState('');
  const [chapterId, setChapterId] = useState('');
  const [releaseStatus, setReleaseStatus] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://oexnab1hue.execute-api.ap-southeast-2.amazonaws.com/admin', { username, password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      if (response.data.success) {
        alert('Login successful!');
        setIsLoggedIn(true);
      } else {
        alert('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const queryParams = new URLSearchParams({
        novelId,
        chapterId,
        releaseStatus: releaseStatus.toString(),
      }).toString();

      const response = await axios.post(`https://oexnab1hue.execute-api.ap-southeast-2.amazonaws.com/release?${queryParams}`);

      console.log('Status update response:', response.data);
      alert('Status updated successfully!');
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Error updating status. Please try again.');
    }
  };

  return (
    <div style={styles.adminPage}>
      {!isLoggedIn ? (
        <div style={styles.container}>
          <h2 style={styles.h2}>Login</h2>
          <form onSubmit={handleLogin} style={styles.form}>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={styles.input}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={styles.input}
            />
            <button type="submit" style={styles.button}>Login</button>
          </form>
        </div>
      ) : (
        <div style={styles.container}>
          <h2 style={styles.h2}>Update Release Status</h2>
          <form onSubmit={handleSubmit} style={styles.form}>
            <input
              type="text"
              placeholder="Novel ID"
              value={novelId}
              onChange={(e) => setNovelId(e.target.value)}
              style={styles.input}
            />
            <input
              type="text"
              placeholder="Chapter ID"
              value={chapterId}
              onChange={(e) => setChapterId(e.target.value)}
              style={styles.input}
            />
            <label style={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={releaseStatus}
                onChange={(e) => setReleaseStatus(e.target.checked)}
                style={styles.checkbox}
              />
              Released
            </label>
            <button type="submit" style={styles.button}>Update Status</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default AdminPage;